@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
}

section {
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

/* Kaydırma çubuğu ana container */
::-webkit-scrollbar {
  width: 10px;
  background-color: #1F2937;
}

/* Kaydırma çubuğu track (arka plan) */
::-webkit-scrollbar-track {
  background-color: #1F2937;
  border-radius: 8px;
}

/* Kaydırma çubuğu thumb (kaydırıcı) */
::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, #8B5CF6, #4ECDC4);
  border-radius: 8px;
  border: 2px solid #1F2937;
}

/* Hover durumunda kaydırma çubuğu */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(to bottom, #7C3AED, #34D399);
}

/* Proje detay sayfası için ek stilller */
.glow-effect {
  position: relative;
}

.glow-effect::before {
  content: '';
  position: absolute;
  inset: -1px;
  background: linear-gradient(90deg, #8B5CF6, #4ECDC4, #8B5CF6);
  border-radius: inherit;
  z-index: -1;
  animation: glow-animation 3s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.glow-effect:hover::before {
  opacity: 1;
}

@keyframes glow-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Parlama animasyonu */
@keyframes pulse-glow {
  0%, 100% {
    box-shadow: 0 0 8px 2px rgba(139, 92, 246, 0.3);
  }
  50% {
    box-shadow: 0 0 12px 4px rgba(78, 205, 196, 0.5);
  }
}

/* Teknoloji etiketleri için hover efekti */
.tech-tag {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.tech-tag::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.1), transparent);
  transition: all 0.5s ease;
}

.tech-tag:hover::before {
  left: 100%;
}

/* Detay kutuları için hover efekti */
.detail-box {
  transition: all 0.3s ease;
}

.detail-box:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px -5px rgba(139, 92, 246, 0.25);
}

/* Oyunları buton efekti */
.play-button {
  position: relative;
  overflow: hidden;
}

.play-button::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    to bottom right,
    rgba(139, 92, 246, 0) 0%,
    rgba(139, 92, 246, 0) 40%,
    rgba(139, 92, 246, 0.3) 50%,
    rgba(139, 92, 246, 0) 60%,
    rgba(139, 92, 246, 0) 100%
  );
  transform: rotate(45deg);
  animation: shine 3s infinite;
}

@keyframes shine {
  0% {
    top: -50%;
    left: -50%;
  }
  100% {
    top: 100%;
    left: 100%;
  }
} 